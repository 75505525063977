import { useState, useEffect } from "react";
import { BaseDialog } from "./BaseDialog";
import { trackEvent, trackSelect } from "../../lib/utils/analytics";
import teamMap from "../../lib/utils/teamMap";
import { getPlayerById } from "../../lib/utils/helpers";
import usePersistentStore from "../../lib/store/PersistentStore";
import useGameStore from "../../lib/store/GameStore";
import { shallow } from "zustand/shallow";
import { MiniGrid } from "./PickHistoryDialog";
import { Divider } from "./Divider";
import { rarityScheme } from "../../lib/utils/rarityScheme";
import { CountUpRarity } from "../Board";
import { calcRarity } from "../../lib/utils/calcRarity";
import { gridLevelNumber } from "../../lib/utils/gridLevels";
import { GridHeadshot } from "./Headshots";

function FriendDialog({ isOpen, closeModal, attempt, rank }) {
  return (
    <BaseDialog
      isOpen={isOpen}
      dark={true}
      closeModal={closeModal}
      modalBody={<ModalBody attempt={attempt} />}
    />
  );
}

function ModalBody({ attempt }) {
  const [guessesLeft] = usePersistentStore(
    (state) => [state.guessesLeft],
    shallow
  );

  return (
    <div className="-mx-4 -mb-4 -mt-8 flex flex-col items-center justify-center bg-neutral-100 text-center">
      <div
        className={`bg-fit flex w-full cursor-pointer items-center justify-between overflow-hidden bg-orange-400 px-4 py-4 font-medium text-slate-200 focus-visible:z-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-[#59d185] dark:border-gray-950 dark:hover:bg-gray-700 sm:px-6 sm:py-4`}
      >
        <div id="name" className="flex items-center text-black">
          <img
            className="mr-3 h-4 sm:h-5"
            src={`/img/flags/${attempt.user.country.code}.svg`}
            alt={`${attempt.user.country.name}" flag`}
          />
          <div className="flex flex-col">
            <div className="line-clamp-1 text-ellipsis text-left text-md font-medium text-black antialiased sm:text-base">
              {attempt.user.displayName}
            </div>
            {/* <div className="line-clamp-1 text-ellipsis text-left text-xxs text-black/70 antialiased group-[.user-row]:text-off-white sm:text-xs">
              Joined Oct '23
            </div> */}
          </div>
        </div>
      </div>
      <DropHeader attempt={attempt} />
      <div className="mb-4 mt-2 flex min-h-[12rem] flex-col justify-center">
        {guessesLeft <= 0 ? (
          <FriendGrid attempt={attempt} />
        ) : (
          <div class="px-6">
            <div class="flex justify-center font-semibold uppercase text-gray-700 dark:text-gray-200">
              View other's grid
            </div>
            <div class="flex justify-center py-4 text-sm text-gray-700 dark:text-gray-400">
              <p>Play today's grid, then see other's selections</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function DropHeader({ attempt }) {
  const bg = attempt.user.country.code.toLowerCase();

  return (
    <>
      <div id="grid-number" className="flex items-center justify-center">
        <div id="left" className="text-black">
          <TabLeft className="h-7 w-7 text-soft-grey sm:h-8 sm:w-8" />
        </div>
        <div
          className={`flex h-7 items-center bg-soft-grey px-2 text-xs font-semibold uppercase tracking-wide text-black antialiased sm:h-8 sm:text-sm`}
        >
          BikeGrid {gridLevelNumber}
        </div>
        <div id="right" className="text-soft-grey">
          <TabRight className="h-7 w-7 sm:h-8 sm:w-8" />
        </div>
      </div>
    </>
  );
}

function TabLeft(props) {
  return (
    <svg width={50} height={50} viewBox="0 0 50 50" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 0H0C4.51409 0 8.65406 2.20577 10.9423 5.8L33.5391 41.3C36.97 46.6913 43.2337 50 50 50V0Z"
        fill="currentColor"
      />
    </svg>
  );
}

function TabRight(props) {
  return (
    <svg width={50} height={50} viewBox="0 0 50 50" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.43311e-06 0H50C45.4859 0 41.3459 2.20577 39.0577 5.8L16.4609 41.3C13.03 46.6913 6.76625 50 2.43311e-06 50V0Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function FriendGrid({ attempt }) {
  const [grid, globalStats] = useGameStore((state) => [
    state.grid,
    state.globalStats,
  ]);

  const rarity = calcRarity(attempt.answers, globalStats);
  const rarityDisplay = isNaN(rarity) ? 900 : rarity;

  return (
    <>
      <div className="-ml-4 -mr-4 flex justify-center">
        <div>
          <div className="flex">
            <div className="w-21 sm:-ml-10 sm:w-32"></div>
            <ColHeader item={grid.hItems[0]} />
            <ColHeader item={grid.hItems[1]} />
            <ColHeader item={grid.hItems[2]} />
          </div>
          <div className="flex items-center">
            <div>
              <RowHeader item={grid.vItems[0]} />
              <RowHeader item={grid.vItems[1]} />
              <RowHeader item={grid.vItems[2]} />
            </div>
            <div className="grid grid-cols-3 overflow-hidden rounded-xl border border-mid-grey/40 bg-white dark:border-gray-950 dark:bg-gray-800">
              <FriendCell attempt={attempt} selectedTab="my" row={1} col={1} />
              <FriendCell attempt={attempt} selectedTab="my" row={1} col={2} />
              <FriendCell attempt={attempt} selectedTab="my" row={1} col={3} />
              <FriendCell attempt={attempt} selectedTab="my" row={2} col={1} />
              <FriendCell attempt={attempt} selectedTab="my" row={2} col={2} />
              <FriendCell attempt={attempt} selectedTab="my" row={2} col={3} />
              <FriendCell attempt={attempt} selectedTab="my" row={3} col={1} />
              <FriendCell attempt={attempt} selectedTab="my" row={3} col={2} />
              <FriendCell attempt={attempt} selectedTab="my" row={3} col={3} />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-2 mt-6 sm:mb-4 md:mt-8">
        <div className="text-center text-sm font-semibold uppercase text-black dark:text-gray-300 sm:text-sm">
          Rarity Score
        </div>
        <div className="flex items-center justify-center ">
          <div className="relative min-w-[8rem] px-8 text-center text-5xl font-semibold leading-none tracking-tight sm:text-6xl">
            <CountUpRarity rarity={rarityDisplay} />
          </div>
        </div>
      </div>
    </>
  );
}

function FriendCell({ attempt, selectedTab, row, col }) {
  const [globalStats] = useGameStore((state) => [state.globalStats]);
  const [cardMode] = usePersistentStore((state) => [state.cardMode]);

  const storedCorrectGuessId = attempt.answers[row - 1][col - 1];
  const displayPlayer = getPlayerById(storedCorrectGuessId);
  const correctAnswersPlayerMap =
    globalStats?.correctAnswersPlayerMap[row - 1][col - 1];
  const selectedPct =
    displayPlayer && correctAnswersPlayerMap
      ? (correctAnswersPlayerMap[displayPlayer.id] / globalStats?.completed) *
        100
      : 0.1;

  const scheme = rarityScheme(selectedPct);
  //  const bg = cardMode ? scheme.class : "bg-[#59d185]";
  const bg = "bg-mid-grey";

  return (
    <div
      className={`flex h-21 w-21 items-center justify-center overflow-hidden border-slate-500 bg-cover bg-center ${
        col < 3 && "border-r"
      } ${
        row < 3 && "border-b"
      } ${bg} text-gray-700 transition-colors duration-75 dark:border-gray-950 sm:h-28 sm:w-28 sm:text-2xl`}
    >
      {displayPlayer && (
        <div className="overflow group relative flex h-full w-full items-start justify-center">
          <GridHeadshot
            player={displayPlayer}
            silhouette={selectedTab === "no_spoilers"}
          />
          {!isNaN(selectedPct) && (
            <div className="absolute right-0 top-0 flex h-4 items-center rounded-bl-lg bg-black pl-1.5 pr-1 text-xxs text-white opacity-90 sm:h-5 sm:pr-1.5">
              {selectedPct < 1
                ? selectedPct.toFixed(1)
                : selectedPct.toFixed(0)}
              %
            </div>
          )}
          {selectedTab != "no_spoilers" && (
            <>
              <div className="absolute bottom-0 left-0 right-0">
                <div className="truncate whitespace-nowrap bg-black px-px py-px text-xxs text-white opacity-90">
                  {displayPlayer.name.includes(" (")
                    ? displayPlayer.name.substring(
                        0,
                        displayPlayer.name.indexOf(" (")
                      )
                    : displayPlayer.name}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

function ColHeader({ item }) {
  const type = item.id.length > 2 ? "award" : "team";

  return (
    <div className="flex h-16 w-21 items-center justify-center text-xs sm:h-20 sm:w-28 ">
      {type == "award" ? (
        <AwardHeader item={item} pos="col" />
      ) : (
        <TeamLogo item={item} />
      )}
    </div>
  );
}

function RowHeader({ item }) {
  const type = item.id.length > 2 ? "award" : "team";

  return (
    <div className="flex h-21 w-21 items-center justify-center text-xs  sm:-ml-10 sm:h-28 sm:w-32">
      {type == "award" ? (
        <AwardHeader item={item} pos="row" />
      ) : (
        <TeamLogo item={item} />
      )}
    </div>
  );
}

export function TeamLogo({ item }) {
  const team = teamMap[item.id];

  return (
    <div>
      <img
        className="h-11 w-11 rounded-full sm:h-14 sm:w-14"
        src={`/img/logos/512/${team.logoId}.png`}
        alt={`${team.full_name} colours`}
      ></img>
    </div>
  );
}

export function AwardHeader({ item, pos }) {
  return (
    <div className="font-display flex h-20 flex-col items-center justify-center text-center text-xs font-bold leading-tight text-black dark:text-white sm:h-28 sm:text-sm  ">
      {item.imgUrl && pos == "col" ? (
        <img
          className="mb-1 mt-1 max-h-9 object-contain px-2 sm:mb-1.5"
          src={item.imgUrl}
          alt={item.title}
        ></img>
      ) : item.imgUrl && pos == "row" ? (
        <img
          className="mb-1 mt-1 max-h-8 w-full object-contain px-1 sm:mb-1.5 sm:max-h-10"
          src={item.imgUrl}
          alt={item.title}
        ></img>
      ) : (
        <div className="leading-tight">{item.title}</div>
      )}
      <div className="text-xxs uppercase text-black dark:text-gray-400 sm:text-xs">
        {item.subtitle}
      </div>
    </div>
  );
}

function StatBox({ title, value }) {
  return (
    <div className="mx-1 flex w-1/3 flex-col rounded-md bg-slate-200 py-2 md:pt-3">
      <div className="mb-1 flex h-8 flex-col justify-center px-4 text-xs font-normal leading-tight opacity-70 md:text-sm">
        {title}
      </div>
      <div className="text-2.5xl md:text-3xl">{value}</div>
    </div>
  );
}

function ChartBar({ label, value, total, mode }) {
  const highlight = value === mode;
  const pct = total == 0 ? 0 : Math.round((value / total) * 100);
  const widthPct = Math.round((value / mode) * 100) * 0.75;
  const barColor = highlight ? "bg-slate-300" : "bg-slate-300";
  return (
    <div className="flex flex-row items-center justify-start">
      <div className="inline-block w-5 text-sm">{label}</div>
      <div className={`inline-block h-5 w-2 ${barColor} my-1`}></div>
      <div
        className={`inline-block h-5 ${barColor} my-1 -ml-0.5 rounded-r-md`}
        style={{ width: `${widthPct}%` }}
      ></div>
      <div className="ml-1.5 inline-block w-8 text-left text-xs opacity-70">
        {pct}%
      </div>
    </div>
  );
}
function ModalCloseButton({ clickCallback }) {
  return (
    <button
      type="button"
      className="absolute right-1.5 -m-1.5 mr-2 rounded-full p-1.5 transition-colors duration-75 hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#59d185] dark:text-gray-300 dark:hover:bg-gray-700"
      onClick={clickCallback}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        aria-hidden="true"
        className="h-6 w-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </button>
  );
}

export default FriendDialog;
