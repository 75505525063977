import useGameStore from "../../lib/store/GameStore";
import TabGroup from "../buttons/TabGroup";
import { GameHistoryList } from "../modals/GameHistoryDialog";
import TabFrames from "../TabFrames";
import BasePage from "./BasePage";

export function PageIndex() {
  const [isWeekend] = useGameStore((state) => [state.isWeekend]);

  return (
    <BasePage>
      <main className="mx-auto min-h-[40rem] min-w-min bg-soft-grey py-4 text-center sm:min-h-[50rem] md:min-h-[58rem] md:py-6">
        {/* <Hero /> */}
        <div className="mx-auto">
          {isWeekend ? (
            <div className="mx-auto max-w-md">
              <div className="mt-8 px-4 sm:mt-12">
                <div className="mb-4 flex justify-center text-xl font-semibold uppercase dark:text-gray-200">
                  Weekend Catch-Up{" "}
                </div>
                <div className="mb-4 flex justify-center text-sm text-gray-800 dark:text-gray-400 sm:mb-8">
                  We're cooking a great new grid for Monday, but why not catch
                  up on the last week of grids?
                </div>
                <div className="rounded-xl bg-white py-2 sm:px-2">
                  <GameHistoryList />
                </div>
              </div>
            </div>
          ) : (
            <>
              <TabGroup />
              <TabFrames />
            </>
          )}
        </div>
        {/* {initError && <NetworkError onRetry={init} />} */}
      </main>
    </BasePage>
  );
}
