import React from "react";
import { trackEvent } from "../../lib/utils/analytics";
import useGameStore from "../../lib/store/GameStore";
import usePersistentStore from "../../lib/store/PersistentStore";
import { shallow } from "zustand/shallow";

export function GiveUpButton() {
  const [giveUp] = usePersistentStore((state) => [state.giveUp], shallow);
  const [setIsResultOpen] = useGameStore(
    (state) => [state.setIsResultOpen],
    shallow
  );

  function handleClick() {
    if (confirm("Are you sure you're finished playing today's grid?") == true) {
      giveUp();
      setIsResultOpen(true);
    }

    trackEvent("give_up_button");
  }

  return (
    <button
      onClick={handleClick}
      className="inline-flex cursor-pointer items-center rounded-full border-2 border-mid-grey/40 bg-white p-2 text-xs font-medium text-gray-700 hover:bg-gray-50  hover:text-black dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 sm:px-3"
    >
      <svg
        className="h-5 w-5 sm:mr-1.5 sm:h-4 md:w-4"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      >
        <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1zM4 22v-7" />
      </svg>
      <span className="hidden sm:inline-block">I'm Done</span>
    </button>
  );
}
