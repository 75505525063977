import React, { useEffect } from "react";
import { trackEvent, trackSelect } from "../../lib/utils/analytics";
import useGameStore from "../../lib/store/GameStore";
import { shallow } from "zustand/shallow";
import { useRouter } from "next/router";
import {
  extractDateFromURLPath,
  gridLevelDateKey,
} from "../../lib/utils/gridLevels";

export default function TabGroup(params) {
  const [selectedTab, setSelectedTab] = useGameStore(
    (state) => [state.selectedTab, state.setSelectedTab],
    shallow
  );

  const router = useRouter();
  const { pathname, query } = router;
  const { tab } = query;

  useEffect(() => {
    if (tab !== undefined) {
      setSelectedTab(tab);
    }
  }, [tab]);

  function onTabClick(tab) {
    const dateKey = extractDateFromURLPath();
    if (dateKey) {
      router.push({
        query: { tab, date: gridLevelDateKey },
        shallow: true,
      });
    } else {
      router.push({
        query: { tab },
        shallow: true,
      });
    }

    setSelectedTab(tab);

    trackEvent(`tab_${tab}`);
  }

  return (
    <>
      <div className="mx-auto mb-4 flex w-min items-center justify-center space-x-1 whitespace-nowrap rounded-full border border-mid-grey/40 bg-white p-0.5 text-center text-xs font-semibold leading-none antialiased md:p-1 md:text-sm">
        <button
          onClick={() => onTabClick("my")}
          className={`rounded-full px-3.5 py-2.5 uppercase md:px-5 ${
            selectedTab == "my" || selectedTab == undefined
              ? "bg-orange-400 text-black"
              : "text-black hover:bg-soft-grey hover:text-black"
          } `}
        >
          My Grid
        </button>
        <button
          onClick={() => onTabClick("stats")}
          className={`rounded-full px-3.5 py-2.5 uppercase md:px-5 ${
            selectedTab === "stats"
              ? "bg-orange-400 text-black"
              : "text-black hover:bg-soft-grey hover:text-black"
          } `}
        >
          Daily Stats
        </button>
        <button
          onClick={() => onTabClick("leaderboard")}
          className={`rounded-full px-3.5 py-2.5 uppercase md:px-5 ${
            selectedTab === "leaderboard"
              ? "bg-orange-400 text-black"
              : "text-black hover:bg-soft-grey hover:text-black"
          } `}
        >
          Leaderboard
        </button>
      </div>
    </>
  );
}
