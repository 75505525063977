import { shallow } from "zustand/shallow";
import useGameStore from "../lib/store/GameStore";
import usePersistentStore from "../lib/store/PersistentStore";
import { IconSparklesSharp } from "./icons/IconSparklesSharp";
import { Divider } from "./modals/Divider";
import {
  AccuracyGrid,
  DailyStats,
  PopularGrid,
  PossibleGrid,
  ScoresChart,
} from "./modals/StatsDialog";

export function StatsTab() {
  const [user] = useGameStore((state) => [state.user]);

  const [guessesLeft] = usePersistentStore(
    (state) => [state.guessesLeft],
    shallow
  );

  const attemptComplete = guessesLeft <= 0;

  return (
    <div className="mx-auto max-w-md">
      {/* <DailyStats />
      <Divider /> */}

      <div className="mt-8 sm:mt-12">
        <div className="mb-4 flex justify-center text-xl font-semibold uppercase dark:text-gray-200">
          Possible Answers
          {/* <IconSparklesSharp className="ml-2 h-5 w-5 text-orange-400" /> */}
        </div>
        <PossibleGrid />
      </div>
      <Divider />

      <div className="mt-8 sm:mt-12">
        <div className="mb-4 flex justify-center text-xl font-semibold uppercase dark:text-gray-200">
          Most Popular{" "}
        </div>
        {!attemptComplete && (
          <div className="my-4 flex justify-center text-sm text-gray-800 dark:text-gray-400">
            Complete today's grid to reveal
          </div>
        )}
        <PopularGrid />
      </div>
      <Divider />
      <div className="mt-8 sm:mt-12">
        <div className="mb-4 flex justify-center text-xl font-semibold uppercase  dark:text-gray-200">
          Accuracy
        </div>
        <AccuracyGrid />
      </div>
      <Divider />
      <div className="mb-12 mt-8 px-4 sm:mt-12">
        <div className="mb-8 flex justify-center text-xl font-semibold uppercase text-black dark:text-gray-200">
          Player Scores
        </div>
        <ScoresChart />
      </div>
    </div>
  );
}
