import React from "react";
import useGameStore from "../../lib/store/GameStore";

export default function GameHistoryButton({ grid, onClick = null }) {
  const [isGameHistoryOpen] = useGameStore((state) => [
    state.isGameHistoryOpen,
  ]);

  return (
    <button
      onClick={onClick}
      className={`flex flex-col items-center justify-center rounded-xl  px-1.5 py-3 hover:bg-white/30 sm:px-2 sm:py-4 sm:hover:border`}
    >
      {/* <img
        className="mb-1 mt-1 w-8 sm:mb-1 sm:w-10 md:w-14"
        src="img/gridley-ball.png"
        alt="BikeGrid Logo"
      ></img> */}
      <div className="text-xxs font-semibold leading-tight text-black antialiased group-hover:text-black sm:text-sm md:text-lg">
        BikeGrid
      </div>
      <div className="-mb-1 flex items-center justify-center">
        <div className="ml-2 text-xs font-semibold leading-tight text-black antialiased group-hover:text-black sm:text-lg md:text-2xl">
          #{grid.levelNumber}
        </div>
        <svg
          fill="currentColor"
          viewBox="0 0 20 20"
          height="1em"
          width="1em"
          className="h-4 w-4 text-black group-hover:text-black sm:h-5 sm:w-5 md:ml-0.5"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </button>
  );
}
